import * as React from "react"
import PropTypes from "prop-types"
import "./layout.css"

const Layout = ({ cls, children }) => {
  return (
    <main className={cls}>
      <div
        style={{
          minHeight: `100vh`,
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem`,
        }}
      >
        <main>{children}</main>
      </div>
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
